export default defineNuxtRouteMiddleware((to, from) => {
  const isAuthenticated = useNuxtApp().$auth.checkAuthentication();

  if (!isAuthenticated && to.path !== "/entrar") {
    return navigateTo("/entrar");
  }
  if (isAuthenticated && to.path === "/entrar") {
    return navigateTo("/contratos");
  }

  return;
});
